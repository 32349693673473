<template>
  <teleport to="body">
    <transition name="fade">
      <div v-if="isVisible" class="spotlight-overlay" @click="dialogClose">
        <div class="speechBubble" :style="bubbleStyle">
          <p>
            気になる商品近くのQRコードをスキャンして<br />ショッピングをお楽しみください！
          </p>
        </div>
        <div
          class="spotlight-area"
          :style="spotlightStyle"
          @click="openCamera"
        ></div>
      </div>
    </transition>
  </teleport>
</template>

<script>
import { computed, defineComponent } from 'vue'

export default defineComponent({
  setup() {
    const store = useCameraNavigationStore()
    const qrCodeReaderStore = useQrCodeReaderStore()
    const isVisible = computed(() => {
      return store.isOpen
    })

    const windowWidth = ref(document.body.clientWidth)
    const windowHeight = ref(document.body.clientHeight)

    const length = 64

    const spotlightStyle = computed(() => {
      return {
        bottom: '20px',
        left: `${windowWidth.value / 2 - length / 2}px`,
        width: `${length}px`,
        height: '90px',
      }
    })

    const bubbleStyle = computed(() => {
      return {
        bottom: '115px',
        left: `${windowWidth.value / 2 - 125}px`,
      }
    })

    const updateWindowSize = () => {
      windowWidth.value = document.body.clientWidth
      windowHeight.value = document.body.clientHeight
    }

    // ウィンドウのリサイズ対応
    onMounted(() => {
      window.addEventListener('resize', updateWindowSize)
    })

    onUnmounted(() => {
      window.removeEventListener('resize', updateWindowSize)
    })

    const dialogClose = () => {
      store.closeDialog()
    }

    const openCamera = () => {
      qrCodeReaderStore.openDialog()
    }

    return {
      isVisible,
      spotlightStyle,
      bubbleStyle,
      dialogClose,
      openCamera,
    }
  },
})
</script>

<style scoped>
.spotlight-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1100;
}

.spotlight-area {
  position: absolute;
  border-radius: 15px;
  background: transparent;
  z-index: 1010;
  box-shadow: 0 0 0 10000px rgba(0, 0, 0, 0.7); /* 外側を黒くする */
}

.speechBubble {
  position: absolute;
  width: 250px;
  height: 50px;
  margin-bottom: 15px;
  padding: 8px 10px;
  border-radius: 10px;
  background-color: #ffffff;
  text-align: center;
  font-size: 11px;
  line-height: 1.5;
  color: #000000;
  z-index: 1100;
}

.speechBubble::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  border-style: solid;
  border-width: 15px 10px 0 10px;
  border-color: #ffffff transparent transparent;
  translate: -50% 100%;
}
</style>
