<template>
  <v-app class="area-theme">
    <v-main class="area-container">
      <slot />
    </v-main>

    <v-footer app fixed color="transparent" height="98">
      <CommonFloatingNavigation />
    </v-footer>
    <OnboardingDialog />
    <OnboardingSpotLight />
    <BSSnackbar
      v-model="snackbar.isVisible.value"
      v-bind="snackbar.snackbarProps"
      @close="snackbar.closeSnackbar"
    />
  </v-app>
</template>

<script lang="ts">
export default defineComponent({
  setup() {
    const snackbar = useSnackbar()
    provide(snackbarInjectionKey, snackbar)

    return {
      snackbar,
    }
  },
})
</script>

<style lang="sass" scoped>
.area-theme
  font-family: $body-font-family
  background-color: $white-bg-color !important
  color: $main-color !important

.area-container
  padding: 0 0 98px 0 !important
</style>
