<template>
  <v-app class="default-layout">
    <v-main :class="{ 'default-container': !canShowFloatingNav }">
      <slot />
    </v-main>
    <v-footer
      v-show="canShowFloatingNav"
      app
      fixed
      color="transparent"
      height="98"
    >
      <CommonFloatingNavigation />
    </v-footer>
    <OnboardingDialog />
    <OnboardingSpotLight />
    <BSSnackbar
      v-model="snackbar.isVisible.value"
      v-bind="snackbar.snackbarProps"
      @close="snackbar.closeSnackbar"
    />
  </v-app>
</template>

<script lang="ts">
export default defineComponent({
  setup() {
    const snackbar = useSnackbar()
    provide(snackbarInjectionKey, snackbar)

    return {
      snackbar,
    }
  },
  computed: {
    canShowFloatingNav(): boolean {
      switch (this.$route.name) {
        case 'cart':
        case 'cart-cartConfrimId-qr':
          return false

        default:
          return true
      }
    },
  },

  mounted() {
    const nuxtApp = useNuxtApp()

    // https://github.com/route06/ss-bonsai/issues/5441
    window.addEventListener('unhandledrejection', function (event) {
      nuxtApp.$sentryCaptureException(
        new Error('Unhandled promise rejection. Reason: ' + event?.reason)
      )
    })
  },
})
</script>

<style lang="sass" scoped>
.default-layout
  background-color: $main-bg-color !important

.default-container
  padding: 0 !important
</style>
